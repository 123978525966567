/*
*  COLORS
*/
/**
*Type
*/
/*
*  BREAKPOINTS
*/
/*
*  IMG
*/
/*
*  FONT SIZES
*/
.Quiz {
  padding: 5px 0 5px 0;
  margin-bottom: 20px; }
  .Quiz__editableTitle {
    font-size: 1.3rem;
    height: 30;
    border: none;
    background-color: transparent;
    border-radius: 0;
    padding-left: 0;
    max-width: 70%; }
  .Quiz__title {
    margin-bottom: 17px; }
  .Quiz__content {
    padding: 12px;
    width: 100%;
    max-height: 70vh;
    overflow-y: auto;
    overflow-x: hidden; }
  .Quiz__detailTitle {
    padding-left: 7px;
    width: 125px;
    float: left; }
  .Quiz__detailContent {
    height: 24px;
    width: calc(100% - 125px);
    float: left; }
  .Quiz__details .ant-row .ant-form-item {
    margin: 0; }
  .Quiz__addQuestion {
    margin: 25px 0 0 0; }
  .Quiz__Faqs, .Quiz__Questions {
    width: 100%; }
    .Quiz__Faqs--title, .Quiz__Questions--title {
      padding-left: 12px;
      margin-top: 15px;
      margin-bottom: 15px; }
  .Quiz__input {
    margin: 0; }
  .Quiz__modal .ant-modal-close {
    display: none; }
  .Quiz__modal--saveButton {
    margin-left: 15px; }
  .Quiz__imageUrl {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden; }
  .Quiz__tree-input {
    margin: 0;
    margin-bottom: 20px; }
    .Quiz__tree-input .ant-form-explain {
      margin-bottom: -20px; }

.QuizQuestion,
.QuizAnswer {
  display: flex;
  align-items: center;
  padding: 5px 10px 5px 10px; }
  .QuizQuestion__header,
  .QuizAnswer__header {
    font-weight: 600; }
  .QuizQuestion__container,
  .QuizAnswer__container {
    display: flex;
    width: 100%;
    min-height: 40px; }
  .QuizQuestion__detailTitle,
  .QuizAnswer__detailTitle {
    padding-left: 7px;
    width: 125px;
    float: left; }
  .QuizQuestion__detailContent, .QuizQuestion__detailInput,
  .QuizAnswer__detailContent,
  .QuizAnswer__detailInput {
    height: 24px;
    width: calc(100% - 300px);
    float: left; }
  .QuizQuestion .QuestionInput,
  .QuizAnswer .QuestionInput {
    width: calc(100% - 20px); }
  .QuizQuestion .deleteButton,
  .QuizAnswer .deleteButton {
    color: #2b2830;
    background-color: transparent;
    border: none;
    font-size: 16px;
    box-shadow: none; }
    .QuizQuestion .deleteButton__disabled,
    .QuizAnswer .deleteButton__disabled {
      border: none;
      background-color: transparent; }
  .QuizQuestion .deleteButton:hover,
  .QuizAnswer .deleteButton:hover {
    color: #df3f41; }

.QuizAnswer__checkbox {
  float: right; }

.QuizQuestion {
  font-weight: 600;
  margin-bottom: 10px; }
  .QuizQuestion__btnAddAnswer {
    display: flex;
    align-items: center;
    margin-top: 20px;
    width: 100%; }
  .QuizQuestion__btnDeleteImage {
    display: flex;
    justify-content: flex-end;
    position: absolute;
    right: 0;
    height: 100%;
    align-items: center;
    margin-top: 15px; }
  .QuizQuestion__media {
    padding-left: 10px;
    padding-right: 15px; }
    .QuizQuestion__media--content {
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden; }
  .QuizQuestion__answers {
    font-weight: 600;
    padding-left: 10px;
    margin-bottom: 7px; }
  .QuizQuestion__deleteButton {
    float: right;
    font-weight: 600;
    border: none;
    background-color: transparent;
    box-shadow: none; }
  .QuizQuestion__deleteButton:focus, .QuizQuestion__deleteButton:hover {
    background-color: transparent; }

.select--maxWidth-300 {
  max-width: 300px; }

.resourceButton {
  margin-right: 10px;
  float: right;
  margin-left: 17px; }
  .resourceButton i {
    float: right;
    margin-left: 10px;
    margin-top: 3px; }
  .resourceButton span {
    margin-left: 0;
    padding-left: 0;
    float: left; }

.Resource__collapse {
  border: 1px solid #d8d8d8;
  border-top: none; }

.Resource__wrapper {
  border: 1px solid #e47072; }

.challengeForm__checkbox {
  padding: 0 10px 0 10px;
  margin: 0; }

.gallery__media--title {
  font-size: 1.1rem;
  font-weight: 600;
  margin-bottom: 7px;
  margin-top: 20px; }

.gallery__media--content {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden; }

.gallery__button {
  margin-top: 7px;
  margin-left: 17px; }

.gallery__row {
  min-height: 30px; }

.gallery__header {
  min-height: 39px; }

.QuestionJustification {
  padding: 5px 10px 0 10px; }

.resourceDivider {
  width: 90%;
  min-width: 90%;
  margin: 15px 0;
  margin-left: auto;
  margin-right: auto; }

.shopping-product-row {
  margin: 20px 0; }

.shopping-product {
  background-color: #f6f6f6;
  padding-left: 10px; }
  .shopping-product .ant-input-number {
    width: 60px; }
  .shopping-product .delete-button-row {
    height: 35px; }
  .shopping-product .delete-button {
    padding-right: 15px; }
  .shopping-product .no-image {
    background-image: url("../../img/noimage.png");
    background-repeat: no-repeat;
    height: 100px;
    width: 120px; }

.shopping-product > .ant-col {
  padding: 0 !important; }
