/*
*  COLORS
*/
/**
*Type
*/
/*
*  BREAKPOINTS
*/
/*
*  IMG
*/
/*
*  FONT SIZES
*/
@import url("https://fonts.googleapis.com/css?family=Nunito:300,400,700|Roboto:100,400,700");
body {
  margin: 0;
  padding: 0;
  font-family: 'Roboto', sans-serif;
  height: 100%; }

#root {
  height: 100%; }

.ant-form-item-label {
  margin-bottom: -5px; }
  .ant-form-item-label > label {
    font-size: 16px !important; }

.ant-form-item {
  margin-bottom: 0; }

.ant-layout-sider,
.ant-menu-dark,
.ant-menu-dark .ant-menu-sub {
  background-color: #d8d8d8 !important; }

.ant-layout,
.mainWrapper .main__content {
  background-color: white !important; }

.ant-layout-sider-trigger,
.ant-menu-dark .ant-menu-inline.ant-menu-sub {
  background-color: #a8a8a8 !important; }

.ant-menu-dark .ant-menu-item-selected .anticon + span,
.ant-menu-item .anticon + span,
.ant-menu-submenu-title .anticon + span {
  color: #2b2830 !important; }

.ant-layout-sider-trigger {
  background-color: #646464 !important; }

.ant-btn-group > .ant-btn-icon-only {
  font-size: 16px !important; }
  .ant-btn-group > .ant-btn-icon-only i {
    padding-bottom: 1px; }

.ant-drawer-mask {
  background: rgba(255, 255, 255, 0) !important;
  backdrop-filter: blur(7px); }

.second-toolbar {
  display: none; }

.fr-top {
  border-radius: 0 !important; }

.fr-wrapper {
  border-bottom: 1px solid #cccccc !important; }

.ant-modal {
  top: 5vh !important; }

.rdw-editor-main {
  background-color: white;
  padding-left: 7px;
  padding-right: 7px; }

.wysTranslation {
  border: 1px solid #d9d9d9 !important; }

.ant-select-selection--multiple {
  cursor: pointer !important; }

.max-width-100 {
  max-width: 100%; }

.assign-points-details-spinner {
  height: 500px;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important; }

.ant-upload.ant-upload-select {
  display: block !important; }
