/*
*  COLORS
*/
/**
*Type
*/
/*
*  BREAKPOINTS
*/
/*
*  IMG
*/
/*
*  FONT SIZES
*/
.Challenge {
  height: calc(100vh - 100px);
  padding-right: 10px;
  padding-left: 10px;
  /*-------- TEMPLATE SELECTOR ---------*/ }
  .Challenge__header {
    color: #d8d8d8;
    background-color: #2b2830;
    width: 100%;
    padding-top: 20px;
    padding-bottom: 40px;
    padding-left: 35px; }
    .Challenge__header--title {
      font-size: 1.6rem; }
    .Challenge__header--description {
      font-size: 1rem; }
  .Challenge .templateSelector {
    background-color: #cecece;
    min-height: calc(100vh - 200px);
    padding: 20px;
    padding-top: 40px;
    position: relative; }
    .Challenge .templateSelector__container {
      min-height: calc(100vh - 290px); }
    .Challenge .templateSelector .challengeCard,
    .Challenge .templateSelector .challengeCard__selected {
      text-align: center;
      display: flex;
      justify-content: center; }
      .Challenge .templateSelector .challengeCard__card,
      .Challenge .templateSelector .challengeCard__selected__card {
        box-shadow: 0px 0px 0px grey;
        -webkit-transition: box-shadow 0.6s ease-out;
        box-shadow: 0.8px 0.9px 3px grey;
        min-height: 180px;
        width: 180px; }
      .Challenge .templateSelector .challengeCard__card:hover,
      .Challenge .templateSelector .challengeCard__selected__card:hover {
        box-shadow: 1px 8px 20px grey;
        -webkit-transition: box-shadow 0.3s ease-in; }
      .Challenge .templateSelector .challengeCard .ant-card-body,
      .Challenge .templateSelector .challengeCard__selected .ant-card-body {
        margin: -2px;
        height: 40px;
        padding: 12px;
        background-color: #2b2830; }
        .Challenge .templateSelector .challengeCard .ant-card-body div,
        .Challenge .templateSelector .challengeCard__selected .ant-card-body div {
          color: #d8d8d8;
          padding: -2px; }
      .Challenge .templateSelector .challengeCard .ant-card-cover,
      .Challenge .templateSelector .challengeCard__selected .ant-card-cover {
        border: nonde;
        display: flex;
        height: calc(180px - 40px);
        align-items: center;
        justify-content: center; }
      .Challenge .templateSelector .challengeCard .ant-card-bordered,
      .Challenge .templateSelector .challengeCard__selected .ant-card-bordered {
        border: none; }
    .Challenge .templateSelector .challengeCard__selected .ant-card-bordered {
      z-index: 99;
      border: 2px solid red; }
    .Challenge .templateSelector .challengeCard__selected .ant-card-body {
      border-bottom: 2px solid red;
      border-left: 2px solid red;
      border-right: 2px solid red; }
    .Challenge .templateSelector .challengeCard .ant-card-bordered {
      z-index: 1;
      border: 2px solid rgba(0, 0, 0, 0); }
    .Challenge .templateSelector__button {
      margin-top: 18px; }
  .Challenge .templateCreator {
    padding: 20px;
    padding-top: 40px;
    background-color: #d8d8d8;
    min-height: calc(100vh - 200px); }
    .Challenge .templateCreator__title {
      font-size: 1rem;
      margin-bottom: 5px; }
    .Challenge .templateCreator__description, .Challenge .templateCreator__description--main {
      font-weight: 700;
      font-size: 1.4rem;
      margin-bottom: 15px; }
    .Challenge .templateCreator__description--main {
      font-size: 1.2rem; }
  .Challenge__modalRow {
    height: 50px; }
  .Challenge__modalSelector {
    float: left;
    width: 200px; }
  .Challenge__modalButton {
    float: right; }

/*-------CHALLENGE EDITOR -------*/
.ChallengeBuilder {
  height: calc(100vh - 80px);
  padding-right: 10px;
  padding-left: 10px; }
  .ChallengeBuilder__leftCol {
    background-color: #f0efef;
    height: 100%;
    padding: 12px;
    padding-top: 25px;
    overflow: auto; }
    @media (max-width: 992px) {
      .ChallengeBuilder__leftCol {
        height: auto; } }
    .ChallengeBuilder__leftCol--title {
      float: left;
      font-size: 16px;
      font-weight: 700;
      margin-bottom: 10px;
      padding-left: 21px; }
    .ChallengeBuilder__leftCol--longDescription {
      float: left;
      font-size: 16px;
      color: rgba(0, 0, 0, 0.85);
      margin-bottom: 10px; }
  .ChallengeBuilder__rightCol {
    background-color: #d8d8d8;
    height: 100%;
    overflow: auto;
    padding-top: 16px; }
    .ChallengeBuilder__rightCol--content {
      color: #2b2830;
      background-color: white;
      margin: 10px; }
  .ChallengeBuilder__editButton, .ChallengeBuilder__editButton--save {
    margin-left: 7px;
    border: none;
    background-color: transparent;
    z-index: 99; }
  .ChallengeBuilder__editButton:hover, .ChallengeBuilder__editButton:focus, .ChallengeBuilder__editButton--save:focus, .ChallengeBuilder__editButton--save:hover {
    background-color: transparent; }
  .ChallengeBuilder__editButton--save {
    color: #df3f41; }
  .ChallengeBuilder__title {
    clear: both;
    height: 41.5px;
    margin-bottom: 40px;
    font-size: 19px; }
  .ChallengeBuilder__saveButton, .ChallengeBuilder__restoreButton {
    z-index: 10;
    float: right;
    margin-left: 21px; }
  .ChallengeBuilder__pointsButton, .ChallengeBuilder__visibilityButton {
    z-index: 10;
    margin-right: 15px; }
  .ChallengeBuilder__headerButtons {
    margin-bottom: 17px; }

/*-------- LOADING MODAL ---------*/
.LoadingModal {
  position: absolute;
  z-index: 99998;
  background-color: rgba(0, 0, 0, 0.5);
  height: 100vh;
  width: 100vw;
  text-align: center;
  vertical-align: middle; }
  .LoadingModal i {
    position: absolute;
    font-size: 125px;
    margin: auto;
    top: calc(50% - 75px); }
    .LoadingModal i svg {
      color: red;
      z-index: 99999; }

/*----- ADD RESOURCE BUTTON ----*/
.CardPopover__container {
  padding: 20px;
  width: 600px;
  text-align: center;
  display: flex;
  justify-content: center; }

.CardPopover__card {
  background-color: #d8d8d8;
  width: 125px;
  height: 125px;
  margin: 0;
  cursor: pointer; }
  .CardPopover__card .ant-card-cover {
    font-weight: 800;
    padding-top: 25px;
    font-size: 40px; }
  .CardPopover__card .ant-card-meta-title {
    font-size: 0.75rem; }
  .CardPopover__card .ant-card-body {
    padding: 24px 0px; }

.CardPopover__cardDisabled {
  background-color: #d8d8d8;
  width: 125px;
  height: 125px;
  margin: 0;
  cursor: not-allowed; }
  .CardPopover__cardDisabled .ant-card-cover {
    font-weight: 800;
    padding-top: 25px;
    font-size: 40px; }
  .CardPopover__cardDisabled .ant-card-meta-title {
    font-size: 0.75rem; }
  .CardPopover__cardDisabled .ant-card-body {
    padding: 24px 0px; }

.ResourcesRender {
  margin-top: 20px;
  padding: 20px; }
  .ResourcesRender__title {
    margin-left: 10px;
    font-weight: 600;
    font-size: 14px; }
  .ResourcesRender__titleButtons {
    margin-right: 10px; }
    .ResourcesRender__titleButtons--order {
      margin-right: 10px; }
  .ResourcesRender__resource {
    margin-bottom: 20px; }
    .ResourcesRender__resource--content {
      margin-left: 20px;
      margin-right: 20px; }
  .ResourcesRender__groupIndex {
    width: 100%;
    min-height: 100px;
    background-color: #d8d8d8;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    font-size: 1.2rem; }

.ResourceButton {
  margin-top: 20px;
  background-color: white;
  padding: 20px; }
  .ResourceButton__content {
    border: 2px dashed #d8d8d8;
    padding: 25px;
    text-align: center; }

/*------ RIGHT COLUMN INFO -----*/
.dateComponent {
  margin: 15px 0 15px 0; }

.slugInput {
  margin-bottom: 30px; }

.dateComponent,
.categoriesSelector,
.duration,
.targetAchivementInput,
.targetEnrollementInput,
.slugInput {
  padding: 0 10px 0 10px; }
  .dateComponent__title,
  .categoriesSelector__title,
  .duration__title,
  .targetAchivementInput__title,
  .targetEnrollementInput__title,
  .slugInput__title {
    margin-bottom: 3px;
    font-weight: 600; }
  .dateComponent__selector, .dateComponent__value,
  .categoriesSelector__selector,
  .categoriesSelector__value,
  .duration__selector,
  .duration__value,
  .targetAchivementInput__selector,
  .targetAchivementInput__value,
  .targetEnrollementInput__selector,
  .targetEnrollementInput__value,
  .slugInput__selector,
  .slugInput__value {
    line-height: 24px;
    height: 24px; }
    .dateComponent__selector span, .dateComponent__value span,
    .categoriesSelector__selector span,
    .categoriesSelector__value span,
    .duration__selector span,
    .duration__value span,
    .targetAchivementInput__selector span,
    .targetAchivementInput__value span,
    .targetEnrollementInput__selector span,
    .targetEnrollementInput__value span,
    .slugInput__selector span,
    .slugInput__value span {
      width: 100%; }

.BasicResource__checkboxContainer {
  margin-bottom: 0; }

.BasicResource__checkbox {
  cursor: pointer;
  margin-bottom: 7px; }
  .BasicResource__checkbox--input {
    float: left;
    margin-right: 20px; }
  .BasicResource__checkbox div:nth-child(even) {
    float: left;
    margin-right: 20px; }

.BasicResource__checkboxTitle {
  float: left; }

.BasicResource__number .ant-input-number {
  width: 100%; }

.BasicResource__image {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden; }

.BasicResource__watchAgain .check-attended {
  position: absolute;
  top: 35px;
  right: 0; }

.challengeForm {
  padding-left: 21px;
  padding-right: 21px; }

.pointsModal .ant-form-item {
  margin-bottom: 7px; }

.pointsModal__likePoints {
  margin-right: 5px;
  margin-bottom: 17px; }

.pointsModal__totalPoints {
  margin-right: 5px;
  margin-bottom: 40px; }

.pointsModal__content {
  max-height: 75vh;
  overflow: hidden; }

.pointsModal__title {
  font-size: 1.6rem;
  margin-bottom: 20px; }

.pointsModal__row {
  color: #2b2830;
  border: 1px solid #d8d8d8;
  background-color: #ffffff;
  padding-left: 10px;
  padding-right: 10px;
  width: 100%; }
  .pointsModal__row--answer {
    padding: 3px;
    padding-left: 15px; }

.pointsModal__row:not(:first-child) {
  border-top: none; }

.pointsModal__lefCol {
  margin-left: 20px; }

.pointsModal__quiz--questionsTitle {
  margin-top: 15px;
  margin-bottom: 15px; }

.pointsModal__quiz--title {
  border: 1px solid #d8d8d8;
  padding: 10px;
  width: 100%; }

.pointsModal__question {
  padding: 10px;
  border: 1px solid #d8d8d8;
  border-bottom: 0; }
  .pointsModal__question--title {
    font-weight: 600;
    margin-bottom: 7px; }

.pointsModal__question:last-child {
  border: 1px solid #d8d8d8; }

.pointsModal .pointsForm {
  max-height: 65vh;
  overflow-y: auto;
  padding-bottom: 75px; }

.pointsModal__talk {
  border: 1px solid #e47072; }
  .pointsModal__talk--title {
    border: 1px solid #d8d8d8;
    padding: 10px; }

.pointsModal__loading {
  min-height: 50vh; }

.pointsModal__collapsible .ant-collapse-header {
  display: flex; }
  .pointsModal__collapsible .ant-collapse-header .panelHeader {
    width: 55%; }
  .pointsModal__collapsible .ant-collapse-header .panelChildren {
    width: 45%; }

.pointsModal .ant-collapse-content {
  width: 100%; }

.pointsModal .ant-collapse > .ant-collapse-item > .ant-collapse-header {
  line-height: 35px; }

.visibilityForm {
  max-height: 65vh;
  overflow-y: auto; }
  .visibilityForm__title {
    font-size: 1.1rem;
    margin-bottom: 17px;
    margin-top: 36px; }
  .visibilityForm__subtitle {
    font-size: 15px;
    margin-bottom: 0px;
    margin-top: 20px; }
  .visibilityForm__remove-all {
    margin-right: 20px; }
  .visibilityForm__select {
    margin-bottom: 40px; }
  .visibilityForm__buttons {
    margin-top: 10px; }
  .visibilityForm__option {
    max-width: 300px;
    margin-bottom: 7px; }
    .visibilityForm__option label {
      margin-right: 10px; }
  .visibilityForm__table {
    width: 100%; }
  .visibilityForm__footer {
    margin-bottom: 20px; }
  .visibilityForm__button {
    margin-top: -20px; }

.assignModal__title {
  font-size: 1.6rem;
  margin-bottom: 17px; }

.assignModal__select {
  min-height: 50vh; }

.ant-calendar-picker-small {
  min-width: 100px !important; }

.fr-wrapper div:first-child {
  display: none; }

.fr-element {
  display: block !important; }

.collapseFrame {
  border: 1px solid #d8d8d8;
  background-color: #ffffff;
  padding: 10px; }

.buttonNoFrame,
.buttonNoFrame:hover,
.buttonNoFrame:active,
.buttonNoFrame:focus {
  border: none;
  background-color: transparent !important;
  color: #df3f41;
  box-shadow: none; }

.buttonNoFrame:hover {
  color: #e48789;
  text-decoration: underline !important; }

.m-r-10 {
  margin-right: 10px; }

.m-t-10 {
  margin-top: 10px; }
